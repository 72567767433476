<template>
  <div class="content">
    <iframe
      v-if="iframeUrl"
      :src="iframeUrl"
      frameborder="0"
      class="iframe"
    ></iframe>

    <div v-else class="empty">
      <img
        src="https://hrp.weilaihos.com/uploads/20210121/98afe4a6068ee7b9cf59bbf25fb16377.png"
        alt=""
      />
      <p class="empty-text">暂无报告单</p>
    </div>
  </div>
</template>

<script>
import { report } from '../services/home'
export default {
  data() {
    return {
      isEmpty: true,
      iframeUrl: undefined
    }
  },
  async created() {
    this.isEmpty = true

    try {
      const {
        AdmNo: vid,
        DocumentType: doctype,
        DocumentID: doid,
        pid
      } = this.$route.query

      console.log(this.$route)

      const res = await report({
        MessageID: 'MES0102',
        PATPatientID: pid,
        PAADMVisitNumber: vid,
        DocumentID: doid,
        DocumentType: doctype
      })

      if (res.status !== 200) return

      const pdfUrl =
        res.data.Response.Body.DocumentSearchRp.Documents.Document.repotUrl

      this.iframeUrl =
        'https://crm.weilaihos.com:12015/Hcrm/MemberService/GetHisPDF?url=' +
        pdfUrl +
        '#scrollbars=0&toolbar=0&statusbar=0'
    } catch (error) {
      console.log(error)
      this.isEmpty = false
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  height: 100vh;
}

.iframe {
  width: 100%;
  height: 100%;
  background: transparent;
}

.back {
  z-index: 100;
}

.empty {
  height: 100vh;
  width: 100%;
  margin: auto;
  text-align: center;
  background: #ffffff;
  color: #909399;
}
.empty img {
  display: block;
  width: 375px;
  height: 293px;
}
.empty .empty-text {
  width: 100%;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ccc;
  line-height: 25px;
  position: absolute;
  top: 230px;
  left: 0px;
  text-align: center;
}
</style>
